import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { ManagedEtaConfiguration } from './launch-darkly';
import { SurveyLinkFlag } from './types';

export const defaultManagedEtaConfiguration: ManagedEtaConfiguration = {
  managedEtaBounds: {
    max: { low: 40, high: 75 },
    min: { low: 10, high: 20 },
  },
  default: true,
};
const defaultSurveyLinkFlag: SurveyLinkFlag = { id: '', url: '', endDate: '' };

const defaultFlags: LDFlagSet = {
  groupOrderActions: false,
  createABatch: false,
  recoverOrder: false,
  reassignOrder: false,
  markOrderUndeliverable: false,
  manualBatching: false,
  spotlightLink: '',
  developerOnlyFeatures: false,
  stagingScreen: false,
  testOrders: false,
  notifications: false,
  cancelOrder: false,
  assignToDriver: false,
  failover: false,
  erroredOrder: false,
  etaAnalytics: false,
  associateDrivers: false,
  enableManualEtaMode: false,
  managedEtaConfiguration: defaultManagedEtaConfiguration,
  dmsSurveyLink: defaultSurveyLinkFlag,
  enableManualCateringEntryFeature: false,
  enableRestaurantConfigPanel: false,
  enableCateringAssignAtFeature: false,
};

export default defaultFlags;
