import React from 'react';
import { createRoot } from 'react-dom/client';
import 'reset-css';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import reportWebVitals from './reportWebVitals';
import Constants from './Constants';
import initializeLaunchDarkly from './launchDarkly/initializeLaunchDarkly';

const App = React.lazy(() => import('./App'));

if (!Constants.IS_CYPRESS_TEST || process.env.NODE_ENV !== 'test') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    version: process.env.REACT_APP_VERSION,
    env: process.env.REACT_APP_ENVIRONMENT,
    traceSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [Constants.BASE_API_URL],
  });
  datadogRum.startSessionReplayRecording();
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    version: process.env.REACT_APP_VERSION,
    env: process.env.REACT_APP_ENVIRONMENT,
    sessionSampleRate: 100,
  });
}

(async () => {
  const LDProvider = await initializeLaunchDarkly();
  const root = createRoot(document.getElementById('root') as Element);

  root.render(
    <React.StrictMode>
      <LDProvider>
        <React.Suspense fallback={null}>
          <App />
        </React.Suspense>
      </LDProvider>
    </React.StrictMode>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
